import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";

export const CtaRightButton = ({ ctaRight, basecolor }) => {
  const { headline, title, actionLabel, actionUrl } = ctaRight;
  return (
    <Box as="section" py="12" bg={mode(`${basecolor}.50`, `${basecolor}.800`)}>
      <Stack
        spacing="6"
        direction={{ base: "column", md: "row" }}
        align={{ md: "center" }}
        justify="space-between"
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <Box>
          <Text
            mb="2"
            fontSize="lg"
            fontWeight="semibold"
            color={mode("gray.600", "gray.400")}
          >
            {headline}
          </Text>
          <Heading size="xl" fontWeight="extrabold" maxW="40ch">
            {title}
          </Heading>
        </Box>
        <Stack
          direction={{ base: "column", sm: "row" }}
          spacing={{ base: "2", sm: "4" }}
        >
          <Button
            as="a"
            href={actionUrl}
            target="_blank"
            size="lg"
            colorScheme={basecolor}
          >
            {actionLabel}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
