import { Box, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import * as React from "react";
import { Testimonial } from "./Testimonial";

export const Testimonials = ({ testimonials, basecolor }) => {
  return (
    <Box as="section" bg={useColorModeValue("gray.50", "gray.800")} py="16">
      <Box maxW={{ base: "xl", md: "7xl" }} mx="auto" px="4">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="10">
          {testimonials.reviews.map((review) => (
            <Testimonial
              key={review.name}
              accentColor={`${basecolor}.500`}
              name={review.name}
              role={review.role}
              image={review.imageUrl}
            >
              {review.description}
            </Testimonial>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};
