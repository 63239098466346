import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { ExperienceCard } from "~/componentes/ExperienceCard";

export function Experiences(props) {
  return (
    <Box
      id="experiences"
      mt={{ base: "8", md: "12" }}
      mb={{ base: "8", md: "12" }}
      maxW="7xl"
      mx="auto"
    >
      <Box
        maxW={{ base: "xl", md: "2xl", lg: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <Box textAlign="center" maxW="4xl" mx="auto">
          <Heading
            size="xl"
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            {props.title}
          </Heading>
          <Text
            mt="4"
            fontSize={{ base: "md", md: "xl" }}
            color={mode("gray.600", "gray.400")}
          >
            {props.subtitle}
          </Text>
        </Box>
        <SimpleGrid
          mt="14"
          columns={{ base: 1, md: 2, lg: 3 }}
          gap={{ base: "8", lg: "16" }}
        >
          {props.experiences.map((item) => (
            <ExperienceCard key={item.id} experience={item} />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}
