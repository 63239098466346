import { Box, Image, Skeleton } from "@chakra-ui/react";
import * as React from "react";

export const ExperienceCard = (props) => {
  const { experience, rootProps } = props;
  return (
    <Box
      position="relative"
      key={experience.id}
      borderRadius="xl"
      overflow="hidden"
      minH={{ base: "sm", lg: "auto" }}
      {...rootProps}
    >
      <Image
        src={experience.imageUrl}
        borderRadius="xl"
        alt={experience.description}
        fallback={<Skeleton />}
      />
    </Box>
  );
};
