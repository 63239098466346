import {
  AspectRatio,
  Box,
  Heading,
  Image,
  Skeleton,
  Stack,
  HStack,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import {
  Carousel,
  CarouselIconButton,
  CarouselSlide,
  useCarousel,
} from "./Carousel";

export const Gallery = (props) => {
  const { title, subtitle, images } = props.gallery;
  const { aspectRatio = 5 / 4, rootProps } = props;
  const [index, setIndex] = React.useState(0);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const defaultSlidesPerView = 5;
  const slidesPerView =
    useBreakpointValue({
      base: 3,
      md: defaultSlidesPerView,
    }) || defaultSlidesPerView;

  const [ref, slider] = useCarousel({
    slides: {
      perView: slidesPerView,
      spacing: useBreakpointValue({
        base: 16,
        md: 24,
      }),
    },
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
  });

  return (
    <Box
      id="gallery"
      maxW="5xl"
      mx="auto"
      px={{ base: "4", md: "8", lg: "12" }}
      py={{ base: "6", md: "8", lg: "12" }}
    >
      <Box
        textAlign="center"
        maxW="4xl"
        mx="auto"
        mb="12"
        mt={{ base: "2", md: "8" }}
      >
        <Heading
          size="xl"
          fontSize={{ base: "2xl", md: "4xl" }}
          fontWeight="extrabold"
          letterSpacing="tight"
        >
          {title}
        </Heading>
        <Text
          mt="4"
          fontSize={{ base: "lg", md: "2xl" }}
          color={mode("gray.600", "gray.400")}
        >
          {subtitle}
        </Text>
      </Box>
      <Stack spacing="4" {...rootProps}>
        <AspectRatio ratio={aspectRatio}>
          <Image
            src={images[index].src}
            objectFit="contain"
            loading="lazy"
            alt={images[index].alt}
            fallback={<Skeleton />}
          />
        </AspectRatio>
        <HStack spacing="4">
          <CarouselIconButton
            onClick={() => slider.current?.prev()}
            icon={<IoChevronBackOutline />}
            aria-label="Previous slide"
            disabled={currentSlide === 0}
          />
          <Carousel ref={ref} direction="row" width="full">
            {images.map((image, i) => (
              <CarouselSlide
                key={i}
                onClick={() => setIndex(i)}
                cursor="pointer"
              >
                <AspectRatio
                  ratio={aspectRatio}
                  transition="all 200ms"
                  opacity={index === i ? 1 : 0.4}
                  _hover={{
                    opacity: 1,
                  }}
                >
                  <Image
                    src={image.src}
                    objectFit="contain"
                    alt={image.alt}
                    loading="lazy"
                    fallback={<Skeleton />}
                  />
                </AspectRatio>
              </CarouselSlide>
            ))}
          </Carousel>
          <CarouselIconButton
            onClick={() => slider.current?.next()}
            icon={<IoChevronForwardOutline />}
            aria-label="Next slide"
            disabled={currentSlide + Number(slidesPerView) === images.length}
          />
        </HStack>
      </Stack>
    </Box>
  );
};
