import { ButtonGroup, IconButton } from "@chakra-ui/react";
import * as React from "react";
import { FaFacebook, FaInstagram, FaLink } from "react-icons/fa";

export const SocialMediaLinks = (props) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton
      as="a"
      href="https://agestoria.com"
      target="_blank"
      aria-label="Website"
      icon={<FaLink fontSize={{ base: "16px", md: "20px" }} />}
    />
    <IconButton
      as="a"
      href="https://instagram.com/agestoriademarketing"
      target="_blank"
      aria-label="Instagram"
      icon={<FaInstagram fontSize={{ base: "16px", md: "20px" }} />}
    />
    <IconButton
      as="a"
      href="https://facebook.com/agestoriademarketing"
      target="_blank"
      aria-label="Facebook"
      icon={<FaFacebook fontSize={{ base: "16px", md: "20px" }} />}
    />
  </ButtonGroup>
);
