import {
  Box,
  Button,
  Flex,
  Heading,
  Img,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";

export function CtaLeft({ ctaLeft }) {
  const { title, description, imageUrl, imageAlt, actionLabel, actionUrl } =
    ctaLeft;
  return (
    <Box as="section" pt={{ base: "12", md: "24" }} overflow="hidden">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <Flex
          align="flex-start"
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          mb="20"
        >
          <Box flex="1" maxW={{ lg: "2xl" }}>
            <Heading
              as="h1"
              size="xl"
              fontSize={{ base: "2xl", md: "4xl" }}
              mt="8"
              fontWeight="extrabold"
            >
              {title}
            </Heading>
            <Text
              color={mode("gray.600", "gray.400")}
              mt="5"
              fontSize={{ base: "xl", md: "2xl" }}
            >
              {description}
            </Text>
            <Button
              as="a"
              target="_blank"
              mt="8"
              minW="14rem"
              colorScheme="orange"
              size="lg"
              height="14"
              px="8"
              fontSize="md"
              fontWeight="bold"
              variant="outline"
              href={actionUrl}
            >
              {actionLabel}
            </Button>
          </Box>
          <Box boxSize={{ base: "20", lg: "8" }} />
          <Img
            pos="relative"
            marginEnd="-12rem"
            w="50rem"
            borderRadius="xl"
            src={imageUrl}
            alt={imageAlt}
          />
        </Flex>
      </Box>
    </Box>
  );
}
