import { Box, Button, Flex, Heading, Img, Stack } from "@chakra-ui/react";
import * as React from "react";

export function Hero({ hero, basecolor = "orange" }) {
  return (
    <Box bg="gray.800" as="section" minH="140px" position="relative">
      <Box py="32" position="relative" zIndex={1}>
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
          color="white"
        >
          <Box maxW="xl">
            <Heading
              as="h1"
              fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
              fontWeight="extrabold"
            >
              {hero.title}
            </Heading>
            <Stack
              direction={{ base: "column", md: "row" }}
              mt="10"
              spacing="4"
            >
              <Button
                as="a"
                target={hero.actionTarget || "_blank"}
                href={hero.actionUrl}
                colorScheme={basecolor}
                px="8"
                size="lg"
                fontSize="md"
                fontWeight="bold"
              >
                {hero.actionLabel}
              </Button>
              <Button
                as="a"
                target="_blank"
                justify={{ base: "center", md: "flex-start" }}
                href={hero.secondActionUrl}
                colorScheme="white"
                px="8"
                size="lg"
                fontSize="md"
                fontWeight="bold"
                variant="outline"
              >
                {hero.secondActionLabel}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box position="relative" w="full" h="full">
          <Img
            src={hero.coverUrl}
            alt="Main Image"
            w="full"
            h="full"
            objectFit="cover"
            objectPosition="top bottom"
            position="absolute"
          />
          <Box position="absolute" w="full" h="full" bg="blackAlpha.600" />
        </Box>
      </Flex>
    </Box>
  );
}
