import {
  Box,
  Center,
  Heading,
  Link,
  SimpleGrid,
  Stack,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import {
  FaClock,
  FaFacebook,
  FaInstagram,
  FaLink,
  FaMapMarked,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import { Feature } from "./Feature";

export const ContactAddress = ({ contact, basecolor }) => {
  return (
    <Box
      id="contact"
      as="section"
      bg={mode("gray.50", "gray.800")}
      pt="12"
      mt={{ base: "12", md: "24" }}
      pb="12"
    >
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <Box>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={{ base: "16", md: "8" }}
          >
            <Stack spacing="6" maxW="2xl">
              <Heading
                letterSpacing="tight"
                fontSize={{ base: "2xl", md: "4xl" }}
                fontWeight="bold"
              >
                {contact.title}
              </Heading>
              <Feature
                basecolor={basecolor}
                icon={<Box as={FaClock} w="4" h="4" />}
              >
                {contact.schedule}
              </Feature>
              <Feature
                basecolor={basecolor}
                icon={<Box as={FaMapMarked} w="4" h="4" />}
              >
                <Link
                  href={contact.addressUrl}
                  textDecoration="underline"
                  target="_blank"
                  _hover={{
                    borderColor: "currentcolor",
                    color: mode(`${basecolor}.600`, `${basecolor}.200`),
                  }}
                >
                  {contact.address}
                </Link>
              </Feature>
              {contact.websiteUrl && (
                <Feature
                  basecolor={basecolor}
                  icon={<Box as={FaLink} w="4" h="4" />}
                >
                  <Link
                    href={contact.websiteUrl}
                    textDecoration="underline"
                    target="_blank"
                    _hover={{
                      borderColor: "currentcolor",
                      color: mode(`${basecolor}.600`, `${basecolor}.200`),
                    }}
                  >
                    {contact.website}
                  </Link>
                </Feature>
              )}
              <Feature
                basecolor={basecolor}
                icon={<Box as={FaInstagram} w="4" h="4" />}
              >
                <Link
                  href={contact.instagramUrl}
                  textDecoration="underline"
                  target="_blank"
                  _hover={{
                    borderColor: "currentcolor",
                    color: mode(`${basecolor}.600`, `${basecolor}.200`),
                  }}
                >
                  {contact.instagram}
                </Link>
              </Feature>
              <Feature
                basecolor={basecolor}
                icon={<Box as={FaFacebook} w="4" h="4" />}
              >
                <Link
                  href={contact.facebookUrl}
                  textDecoration="underline"
                  target="_blank"
                  _hover={{
                    borderColor: "currentcolor",
                    color: mode(`${basecolor}.600`, `${basecolor}.200`),
                  }}
                >
                  {contact.facebook}
                </Link>
              </Feature>
              <Feature
                basecolor={basecolor}
                icon={<Box as={FaWhatsapp} w="4" h="4" />}
              >
                {contact.whatsapp}
              </Feature>
              {contact.phone && (
                <Feature
                  basecolor={basecolor}
                  icon={<Box as={FaPhone} w="4" h="4" />}
                >
                  {contact.phone}
                </Feature>
              )}
            </Stack>
            <Center shadow="lg" minH="26rem">
              <iframe
                title="Mapa do Restaurante no Google Maps"
                src={contact.mapsUrl}
                width="600"
                height="400"
                style={{ border: 0 }}
                loading="lazy"
              />
            </Center>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};
