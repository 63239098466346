import { Box } from "@chakra-ui/react";
import { Hero } from "~/componentes/Hero";
import { Experiences } from "~/componentes/Experiences";
import { Footer } from "~/componentes/Footer";
import { CtaLeft } from "~/componentes/CtaLeft";
import { Gallery } from "~/componentes/Gallery";
import { AboutUs } from "~/componentes/AboutUs";
import { Testimonials } from "~/componentes/Testimonials";
import { ContactAddress } from "~/componentes/ContactAddress";
import { CtaRightButton } from "~/componentes/CtaRightButton";
import { Navbar } from "~/componentes/Navbar";
import { useLoaderData } from "remix";
import { getDataByCompany } from "~/data/_data";
import * as React from "react";

export let loader = async ({ request }) => {
  const host = request.headers.get("host");
  return { website: getDataByCompany({ host }) };
};

export const meta = ({ data }) => {
  const { website } = data;
  const { company } = website;
  return {
    title: company.name,
    description: company.description,
    "og:description": company.description,
    "og:image": company.socialImageUrl,
    "twitter:card": company.socialImageUrl,
  };
};

export default function Index() {
  const { website } = useLoaderData();
  const {
    company,
    navbar,
    hero,
    experiences,
    testimonials,
    ctaLeft,
    ctaRight,
    aboutUs,
    gallery,
    contact,
  } = website;
  const { basecolor } = company;
  return (
    <Box>
      {navbar && company && <Navbar navbar={navbar} company={company} />}
      {hero && <Hero hero={hero} basecolor={basecolor} />}
      {experiences && (
        <Experiences
          title={experiences.title}
          subtitle={experiences.subtitle}
          experiences={experiences.items}
        />
      )}
      {testimonials && (
        <Testimonials testimonials={testimonials} basecolor={basecolor} />
      )}
      {ctaLeft && <CtaLeft ctaLeft={ctaLeft} />}
      {ctaRight && <CtaRightButton ctaRight={ctaRight} basecolor={basecolor} />}
      {aboutUs && <AboutUs aboutUs={aboutUs} basecolor={basecolor} />}
      {gallery && <Gallery gallery={gallery} />}
      {contact && <ContactAddress contact={contact} basecolor={basecolor} />}
      {company && <Footer company={company} />}
    </Box>
  );
}
