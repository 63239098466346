import { Center, Stack, Text } from "@chakra-ui/react";
import * as React from "react";

export const Feature = (props) => {
  const { children, icon, basecolor } = props;
  return (
    <Stack direction="row" spacing="2">
      <Center
        aria-hidden
        flexShrink={0}
        w="6"
        h="6"
        rounded="md"
        color="white"
        bg={`${basecolor}.400`}
      >
        {icon}
      </Center>
      <Stack direction="row">
        <Text
          fontWeight="semibold"
          color="gray.600"
          fontSize={{ base: "sm", md: "md" }}
        >
          {children}
        </Text>
      </Stack>
    </Stack>
  );
};
